<template>
  <div
    style="display: flex; padding-bottom: 120px; position: relative"
    class="px-md-5 pt-5 container"
  >
    <vue-progress-bar></vue-progress-bar>
    <div v-if="loading || !page || !page.place_set" class="spinner">
      <b-spinner big></b-spinner>
    </div>
    <div v-else-if="needTorelax">
      <div>
        <Logo
          :logo="simInfo.logo"
          v-if="simInfo.logo && isMobile"
          style="margin-bottom: 20px"
        />
        <Slide v-if="isMobile" right :width="getWidth">
          <Sidebar />
        </Slide>
      </div>
      <h1 class="course__title">
        {{ page.is_onboarding ? simInfo.onboarding_name : page.name }}
      </h1>
      <div v-html="simInfo.settings.pause_text"></div>
      <Countdown v-if="needTorelaxEnd" :end="needTorelaxEnd"></Countdown>
    </div>
    <div v-else-if="needTorelaxPage">
      <div>
        <Logo
          :logo="simInfo.logo"
          v-if="simInfo.logo && isMobile"
          style="margin-bottom: 20px"
        />
        <Slide v-if="isMobile" right :width="getWidth">
          <Sidebar />
        </Slide>
      </div>
      <h1 class="course__title">
        {{ page.is_onboarding ? simInfo.onboarding_name : page.name }}
      </h1>
      <div v-html="thisLesson.pause_text"></div>
      <Countdown
        v-if="needTorelaxEndPage"
        :end="needTorelaxEndPage"
      ></Countdown>
    </div>
    <div class="course px-lg-5 w-100" v-else>
      <div>
        <Logo
          :logo="simInfo.logo"
          v-if="simInfo.logo && isMobile"
          style="margin-bottom: 20px"
        />
        <Slide v-if="isMobile" right :width="getWidth">
          <Sidebar />
        </Slide>
      </div>
      <h1 class="course__title">
        {{ page.is_onboarding ? simInfo.onboarding_name : page.name }}
      </h1>
      <div class="course__content" v-if="page && page.place_set">
        <transition-group name="fade">
          <template v-for="(item, index) in page.place_set">
            <div class="course__item" :id="`story-${index}`" :key="index">
              <CharMessage
                :place_id="item.id"
                :direction="item.content_object.is_hero ? 'right' : 'left'"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-if="
                  !item.content_object.is_author_message &&
                  item.content_object.type === 'message'
                "
                :object="item.content_object"
                :page="pageId"
                :sequence_no="item.sequence_no"
              />
              <Message
                :place_id="item.id"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-else-if="
                  item.content_object.is_author_message &&
                  item.content_object.type === 'message'
                "
                :object="item.content_object"
                :page="pageId"
                :sequence_no="item.sequence_no"
              />
              <SafeText
                :place_id="item.id"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-else-if="item.content_object.type === 'safe_text'"
                :object="item.content_object"
                :page="pageId"
                :sequence_no="item.sequence_no"
              />
              <Theory
                :place_id="item.id"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-else-if="item.content_object.type === 'theory'"
                :object="item.content_object"
                :page="pageId"
                :sequence_no="item.sequence_no"
              />
              <OpenQuestion
                :place_id="item.id"
                :complete="index != page.place_set.length - 1"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-else-if="item.content_object.type === 'open_question'"
                :page="pageId"
                :sequence_no="item.sequence_no"
                :object="item.content_object"
              />
              <OpenQuestionExpert
                :place_id="item.id"
                :complete="index != page.place_set.length - 1"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-else-if="item.content_object.type === 'open_question_expert'"
                :page="pageId"
                :sequence_no="item.sequence_no"
                :object="item.content_object"
              />
              <Question
                :place_id="item.id"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-else-if="item.content_object.type === 'question'"
                :page="pageId"
                :sequence_no="item.sequence_no"
                :object="item.content_object"
              />
              <QuestionCheck
                :place_id="item.id"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-else-if="item.content_object.type === 'question_answer_check'"
                :page="pageId"
                :sequence_no="item.sequence_no"
                :object="item.content_object"
              />
              <Choose
                :place_id="item.id"
                :button="
                  index == page.place_set.length - 1 &&
                  (!page.complete ||
                    (page.complete &&
                      page.place_set_count != page.place_set.length))
                "
                v-else-if="item.content_object.type === 'question_user_choice'"
                :page="pageId"
                :sequence_no="item.sequence_no"
                :object="item.content_object"
              />
            </div>
          </template>
        </transition-group>
        <div class="course__end" id="end">
          <EndCourse
            v-if="page.complete && page.is_onboarding"
            :is_onboarding="page.is_onboarding"
            :page="page.id"
            @endPage="nextPage"
          />
          <EndCourse
            v-else-if="page.complete && !page.user_fun"
            :page="page.id"
            @endPage="nextPage"
          />
          <EndCourse
            v-else-if="page.complete && page.user_fun"
            :page="page.id"
            :fun="page.user_fun"
            :utility="page.user_utility"
          />
        </div>
      </div>
    </div>
    <!-- <CourseSidebar /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Message from "@/components/course/Message.vue";
import CharMessage from "@/components/course/CharMessage.vue";
// import CourseSidebar from '@/components/course/CourseSidebar.vue'
import SafeText from "@/components/course/SafeText.vue";
import Theory from "@/components/course/Theory.vue";
import OpenQuestion from "@/components/course/OpenQuestion.vue";
import OpenQuestionExpert from "@/components/course/OpenQuestionExpert.vue";
import QuestionCheck from "@/components/course/QuestionCheck.vue";
import Question from "@/components/course/Question.vue";
import Choose from "@/components/course/Choose.vue";
import EndCourse from "@/components/course/EndCourse.vue";
import Logo from "@/components/makeUser/Logo";
import { Slide } from "vue-burger-menu";
import Sidebar from "@/views/SideBar";
import Countdown from "vuejs-countdown";

export default {
  name: "Course",
  components: {
    Message,
    Sidebar,
    EndCourse,
    Theory,
    OpenQuestionExpert,
    CharMessage,
    OpenQuestion,
    Slide,
    // CourseSidebar,
    Question,
    QuestionCheck,
    SafeText,
    Logo,
    Countdown,
    Choose,
  },
  data() {
    return {
      loading: true,
      no_onboarding: false, 
      CurrentDate: this.$moment()
    };
  },
  created() {
    var self = this
    setInterval(function () {
         self.CurrentDate = self.$moment()
    }, 1000)
},
  mounted() {
    this.CurrentDate = this.$moment()
    this.$Progress.setColor(this.simInfo.color);

    this.getNotifications()
    this.getPage();

    this.$store.dispatch("utility/ChangeLoading", false);
  },
  methods: {
    async getNotifications()
    {
      this.$store.dispatch("notifications/GET_NOTIFICATIONS");
    },
    async getPage() {
      await this.$store.dispatch("page/CLEAR_ALL_PAGE");
      if (this.user.sim_info) {
        if (this.user.sim_info.current_page) {
          if (!this.user.sim_info.onboarding_complete) {
            if (this.simInfo.onboarding_skip) {
              if (
                !this.user.sim_info.current_page || this.user.sim_info.current_page == 0
              ) {
 
                await this.$store.dispatch("page/SET_PAGE", {
                  vm: this,
                  page: 0,
                });


                this.$router.push("/courses");
              } else {
                this.$store.dispatch(
                  "page/FETCH_PAGE",
                  this.user.sim_info.current_page
                );
              }
            } else {
              await this.$store.dispatch("page/FETCH_PAGE", 0);
            }
          } else {
            await this.$store.dispatch(
              "page/FETCH_PAGE",
              this.user.sim_info.current_page
            );
          }
        } else {
          if (this.to_onboarding && !this.simInfo.onboarding_skip) {
            this.$store.state.page.to_onboarding = false;
            await this.$store.dispatch("page/FETCH_PAGE", 0);
          } else {

            this.$router.push("/courses");
          }
        }
        this.loading = false;
      }
    },
    async nextPage() {
      this.$store.dispatch("utility/ChangeLoading", true);
      var next = this.page.next_page;
      if (
        this.page.curr_user_score < this.page.need_answers &&
        this.page.is_test
      ) {
        next = this.page.next_page_if_failed;
      }
      if (this.page.is_onboarding || !next) {
        if (this.page.is_onboarding) {
          await this.$store.dispatch("page/SET_PAGE", {
            vm: this,
            page: 0,
          });
          await this.$store.dispatch("user/FETCH");
          await this.$store.dispatch("page/CLEAR_ALL_PAGE");
          if (this.simInfo.notifications_url) {
            this.$store.dispatch("simulator/SEND_NOTIFICATION", {
              type: "onboarding",
            });
          }

          this.$router.push("/courses");
        } else {
          let seqno = this.simInfo.lesson_set.findIndex(
            (x) => x.id == this.page.lesson
          );
          if (this.simInfo.notifications_url) {
            this.$store.dispatch("simulator/SEND_NOTIFICATION", {
              type: "page",
              page_id: this.page.id,
              page_name: this.page.name,
            });
            this.$store.dispatch("simulator/SEND_NOTIFICATION", {
              type: "lesson",
              lesson_id: this.page.lesson,
              lesson_name: this.simInfo.lesson_set[seqno].name,
            });
          }
          if (this.simInfo.lesson_set.length == seqno + 1) {
            await this.$store.dispatch("page/SET_PAGE", {
              vm: this,
              page: 0,
            });
            await this.$store.dispatch("user/FETCH");
            await this.$store.dispatch("page/CLEAR_ALL_PAGE");

            this.$router.push("/courses");
            //сделать переход на сертификат
          } else {
            if (
              (this.simInfo.paid ||
                this.simInfo.price == 0 ||
                this.simInfo.lesson_set[seqno + 1].price == 0) &&
              this.simInfo.lesson_set[seqno + 1].active
            ) {
              this.$store.commit("page/CLEAR_PAGE");

              await this.$store.dispatch("simulator/FETCH");
              this.no_onboarding = true

              await this.$store.dispatch("page/SET_PAGE", {
                vm: this,
                page: this.simInfo.lesson_set[seqno + 1].current_page,
              });


              await this.getPage();

              this.$store.dispatch("utility/ChangeLoading", false);
            } else {
              await this.$store.dispatch("page/SET_PAGE", {
                vm: this,
                page: 0,
              });
              await this.$store.dispatch("user/FETCH");
              await this.$store.dispatch("page/CLEAR_ALL_PAGE");

              this.$router.push("/courses");
            }
          }
        }
      } else {
        if (this.simInfo.notifications_url) {
          this.$store.dispatch("simulator/SEND_NOTIFICATION", {
            type: "page",
            page_id: this.page.id,
            page_name: this.page.name,
          });
        }
        await this.$store.dispatch("page/SET_PAGE", {
          vm: this,
          page: next,
        });
        this.$store.commit("page/CLEAR_PAGE");
        this.getPage();
        this.$store.dispatch("utility/ChangeLoading", false);
      }
    },
  },
  computed: {
    ...mapGetters({
      simInfo: "simulator/getSimInfo",
      user: "user/getMeInfo",
      page: "page/getPageInfo",
      isMobile: "utility/getIsMobile",
      to_onboarding: "page/getShowOnboarding",
    }),
    getWidth() {
      return `${window.innerWidth}`;
    },
    pageId() {
      if (this.page.is_onboarding) {
        return 0;
      }
      return this.page.id;
    },
    thisLesson() {
      let seqno = this.simInfo.lesson_set.findIndex(
        (x) => x.id == this.page.lesson
      );
      return this.simInfo.lesson_set[seqno];
    },
    needTorelaxEndPage() {
      if (this.page.is_onboarding || this.simInfo.settings.need_pause) {
        return false;
      }
      let seqno = this.simInfo.lesson_set.findIndex(
        (x) => x.id == this.page.lesson
      );
      if (seqno == 0) {
        return false;
      }
      if (!this.simInfo.lesson_set[seqno - 1].complete_time) {
        return this.$moment()
          .add(this.simInfo.lesson_set[seqno].pause_length, "hours")
          .format("YYYY/MM/DD HH:mm");
      }
      return this.$moment(this.simInfo.lesson_set[seqno - 1].complete_time)
        .add(this.simInfo.lesson_set[seqno].pause_length, "hours")
        .format("YYYY/MM/DD HH:mm");
    },
    needTorelaxPage() {
      if(!this.simInfo.order_lesson)
      {
        return false
      }
      if (this.page.is_onboarding || this.simInfo.settings.need_pause) {
        return false;
      }
      let seqno = this.simInfo.lesson_set.findIndex(
        (x) => x.id == this.page.lesson
      );
      if (seqno == 0) {
        return false;
      }
      var endOfWait = null;
      if(!this.simInfo.lesson_set[seqno - 1].complete_time)
      {
        endOfWait = this.$moment()
          .add(this.simInfo.lesson_set[seqno].pause_length, "hours")
      }
      else{
        endOfWait = this.$moment(this.simInfo.lesson_set[seqno - 1].complete_time)
        .add(this.simInfo.lesson_set[seqno].pause_length, "hours")
      }
      console.log(this.simInfo.lesson_set[seqno - 1].complete_time)
      return this.CurrentDate.isBefore(endOfWait);
    },
    needTorelax() {
      if(!this.simInfo.order_lesson)
      {
        return false
      }
      if (this.page.is_onboarding) {
        return false;
      }
      if (!this.simInfo.settings.need_pause) {
        return false;
      }
      let seqno = this.simInfo.lesson_set.findIndex(
        (x) => x.id == this.page.lesson
      );
      if (seqno == 0) {
        return false;
      }
      let endOfWait = this.$moment(
        this.simInfo.lesson_set[seqno - 1].complete_time
      ).add(this.simInfo.settings.pause_length, "hours");
      return this.CurrentDate.isBefore(endOfWait);
    },
    needTorelaxEnd() {
      if (!this.simInfo.settings.need_pause) {
        return false;
      }
      if (this.page.is_onboarding) {
        return false;
      }
      let seqno = this.simInfo.lesson_set.findIndex(
        (x) => x.id == this.page.lesson
      );
      if (seqno == 0) {
        return false;
      }
      if (!this.simInfo.lesson_set[seqno - 1].complete_time) {
        return this.$moment()
          .add(this.simInfo.settings.pause_length, "hours")
          .format("YYYY/MM/DD HH:mm");
      }
      return this.$moment(this.simInfo.lesson_set[seqno - 1].complete_time)
        .add(this.simInfo.settings.pause_length, "hours")
        .format("YYYY/MM/DD HH:mm");
    },
  },
  watch: {
    page(val) {
      if (val && val.place_set) {
        this.$store.dispatch("user/FETCH_BALANCE");
        if (val.complete) {
          if (
            this.loading ||
            val.place_set.length == 1 ||
            !(
              val.place_set[val.place_set.length - 2].content_object.type ==
                "open_question" ||
              val.place_set[val.place_set.length - 2].content_object.type ==
                "open_question_expert" ||
              val.place_set[val.place_set.length - 2].content_object.type ==
                "question"||
                val.place_set[val.place_set.length - 2].content_object.type ==
                "question_user_choice"
                ||
                val.place_set[val.place_set.length - 2].content_object.type ==
                "question_answer_check"
            )
          ) {
            setTimeout(() => {
            this.$scrollTo(`#end`, 1200, { easing: "linear" });
          }, 100);
          }

          
        } else {
          if (
            this.loading ||
            val.place_set.length == 1 ||
            !(
              val.place_set[val.place_set.length - 2].content_object.type ==
                "open_question" ||
              val.place_set[val.place_set.length - 2].content_object.type ==
                "open_question_expert" ||
              val.place_set[val.place_set.length - 2].content_object.type ==
                "question"||
                val.place_set[val.place_set.length - 2].content_object.type ==
                "question_user_choice"|| 
                val.place_set[val.place_set.length - 2].content_object.type ==
                "question_user_choice"
                ||
                val.place_set[val.place_set.length - 2].content_object.type ==
                "question_answer_check"
            )
          ) {
            setTimeout(() => {
              this.$scrollTo(`#story-${val.place_set.length - 1}`, 900, {
                easing: "linear",
              });
            }, 100);
          }
        }

        this.$Progress.set((val.place_set.length / val.place_set_count) * 100);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("page/CLEAR_PAGE");
  },
};
</script>

<style lang="scss">
.vuejs-countdown .text {
  display: none;
}
.bm-cross-button {
  right: 0 !important;
  top: 28px !important;
}
.bm-burger-button {
  top: 69px;
}
.bm-cross {
  background-color: #000000;
}

.course__item .message__author__avatar img {
  max-width: unset !important;
}
.course__item img {
  max-width: 100% !important;
  height: auto;
}
.course__item table td {
  vertical-align: unset;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/vars.scss";

$spacer: 3rem;
.fade-enter-active {
  transition: opacity 1.2s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.spinner {
  position: fixed;
  width: calc(5 / 6 * 100vw);
  height: 100vh;
  top: 0;
  left: calc(1 / 6 * 100vw);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 930px) {
  .spinner {
    width: 98vw;
    left: 0;
  }
}
.course {
  // padding: 120px 100px 0px 100px;
  // width: calc(5 / 6 * 100vw);
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-weight: 700;
    font-size: $bigTextSize;
    margin-bottom: 70px;
  }
  &__end {
    // padding: 0px 160px;
    width: 85%;
    align-self: center;
  }
  &__item {
    margin-bottom: 40px;
    display: table;
    width: 100%;
  }
}
@media (max-width: 575.8px) {
  .course__end {
    width: 100%;
  }
}
</style>