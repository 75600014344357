<template>
    <div class="question" :class="{answered: object.passed}">
        <div class="d-flex justify-content-between">
            <ContentHeader :type="object.title&&object.title!=''?object.title:'Тестовое задание'" :text="object.text" :description='object.text_description'/>
            <b-spinner small v-if="process" ></b-spinner>
        </div>
        <div class="question__answers">
            <div class="question__answers__item" v-for="(answer, index) in answer_set" :key="index">
                <AnswerButton :correct_nochosen="object.passed && !answer.is_correct && !answer.chosen" :incorrect_nochosen="object.passed && answer.is_correct && !answer.chosen" :text="answer.text" @click="chooseAnswer(answer)" :active="!process && !object.passed" :type="getType(answer)"/>
            </div>
        </div>
        <div v-if="!object.passed && object.multiply_answer && answers_set.length > 0">
            <FormButton :loading="loading" text="Ответить" :inactive="process" size="medium-large" color="primary" @click="sendAnswer"/>
        </div>
        <div v-if="object.passed" :id="'answer-'+object.id">
            <AuthorAnswer :text="is_chosen_right === undefined ? authorAnswer : (is_chosen_right ? object.all_right_comment : object.not_all_right_comment)" style="margin-top:20px !important;"/>
            <AfterTaskMessage/>
        </div>
    </div>
    
</template>

<script>
import ContentHeader from '@/components/global/course/ContentHeader'
import AuthorAnswer from '@/components/global/course/AuthorAnswer';
import AfterTaskMessage from '@/components/global/course/AfterTaskMessage';
import AnswerButton from '@/components/global/course/AnswerButton'
import FormButton from '@/components/global/form/FormButton';
import { mapGetters } from 'vuex';

export default {
    props: ['object', 'page', 'sequence_no', 'place_id'],
    components: {
        ContentHeader,
        FormButton,
        AnswerButton,
        AuthorAnswer,
        AfterTaskMessage,
    },
    mounted(){
        // this.object.multiply_answer = true;
    },
    data(){
        return{
            process: false,
            answers_set: [],
            type: 1
        }
    },
    computed:{
        ...mapGetters({
            loading: 'utility/getCourseTaskLoading'
        }),
        answer_set(){
            let answers = [...this.object.answer_set].sort((val1, val2) => (val1.id > val2.id) ? 1 : -1)
            return answers
        },
        answer_chosen_value(){
            return this.object.answer_set.filter(obj => {
                return this.answers_set.includes(obj.id)
            })
        },
        is_chosen_right(){
            if (this.object && this.object.multiply_answer){
                let result = true
                this.object.answer_set.forEach(ans => {
                    if ((ans.chosen && !ans.is_correct) || (!ans.chosen && ans.is_correct)){
                        result = false
                    }
                });
                return result
            }
            return undefined
        },
        authorAnswer(){
            if (!this.object.passed){
                return undefined
            }
            let comment = '';
            this.object.answer_set.forEach(ans => {
                if (ans.chosen){
                    comment = ans.comment;
                }
            });
            return comment;
        }
    },
    methods:{
        getType(answer){
            if (answer.chosen === true){
                if (answer.is_correct){
                    return 'success'
                }else{
                    return 'wrong'
                }
            }
            return 'default'
        },
        chooseAnswer(answer){
            if (this.answers_set.includes(answer.id)){
                this.answers_set = this.answers_set.filter(el=> el !== answer.id)
            }else{
                this.answers_set.push(answer.id);
            }
            if (!this.object.multiply_answer){
                this.sendAnswer();
            }
        },
        async sendAnswer(){
            this.process = true;
            this.$store.commit("utility/SET_COURSE_TASK_LOADING", true)
            await this.$store.dispatch("course/SEND_QUESTION", {
                page_id: this.page,
                seq_no: this.sequence_no,
                ids: this.answers_set,
                place: this.place_id,
            })
            this.$store.commit("utility/SET_COURSE_TASK_LOADING", false)
            this.process = false
            setTimeout(() => {
                this.$scrollTo(`#answer-${this.object.id}`,1200, { easing: "linear" })    
            }, 100);
            if(this.object.need_notifications)
            {
                this.$store.dispatch("simulator/SEND_NOTIFICATION", {
                    type: 'question',
                    id : this.object.id,
                    text: this.object.title,
                    answer: this.answer_chosen_value.map(function(elem){
                        return elem.text;
                    }).join(', ')
                })
            }
            
        }
    }
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';

.question{
    margin-bottom: 40px;
    padding: 25px 30px 30px 30px;
    border: solid 1px $third_color;
    border-radius: 10px;
    &__answers{
        flex-wrap: wrap;
        display: flex;
        &__item{
            margin-right: 20px;
        }
    }
    &.answered{
        background-color: $second_background_color;
        border: none;
    }
}
</style>