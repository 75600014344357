import { render, staticRenderFns } from "./MarkCharMessage.vue?vue&type=template&id=43684f51&scoped=true&"
import script from "./MarkCharMessage.vue?vue&type=script&lang=js&"
export * from "./MarkCharMessage.vue?vue&type=script&lang=js&"
import style0 from "./MarkCharMessage.vue?vue&type=style&index=0&id=43684f51&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43684f51",
  null
  
)

export default component.exports